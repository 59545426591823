import { useIsSealdOnly } from "containers/SealdOnlyProvider";
import {
  NEGATIVE_DIFF,
  POSITIVE_DIFF,
  PREDICAMENT_STATE_UNKNOWN,
  PREDICAMENT_STATE_YES,
  SEARCH_ACTION_EDIT_SEARCH,
  SIMPLE_DIFF,
  TRACK_EVENTS,
} from "core/consts";
import { descriptiveWhitelist, findSearchAction } from "core/model/auctions";
import {
  getEncryptedValue,
  getPatientSessionKey,
} from "core/model/patients/encryption";
import { convertBirthDateIn } from "core/model/utils/dates";
import { isNewTheme } from "core/model/utils/featureFlags";
import { formatLocation } from "core/model/utils/location";
import { OntologyType } from "core/model/utils/ontologies";
import { renderMultiLine } from "core/model/utils/text";
import {
  Auction,
  EncryptedField as EncryptedValue,
  GetOntologyType,
  Location,
  PatientDiffType,
  PatientWhitelistDefinition,
} from "core/types";
import { LockIcon } from "ds/icons";
import { Chip, PrintableChip } from "ds_legacy/components/Chip";
import { LockSecurePin } from "ds_legacy/components/Icons/SecurePin";
import InfoCard, {
  CardContentContainer,
  InfoCardLite,
} from "ds_legacy/components/InfoCard";
import Tooltip from "ds_legacy/components/Tooltip";
import {
  ICON_DARK,
  ICON_LIGHT_GREY,
  NEGATIVE_DIFF_STYLE,
  POSITIVE_DIFF_STYLE,
  STATUS_BADGE_MEDIUM_GREY,
} from "ds_legacy/materials/colors";
import { HorizontalLayout, VerticalLayout } from "ds_legacy/materials/layouts";
import {
  dp,
  margin,
  padding,
  sizing,
  translate,
} from "ds_legacy/materials/metrics";
import {
  Body,
  Chip as ChipText,
  FONT_SIZE_12,
  FONT_SIZE_18,
  FONT_WEIGHT_BOLD,
  FONT_WEIGHT_REGULAR,
  Subheading,
  Title,
} from "ds_legacy/materials/typography";
import { usePrint } from "dsl/atoms/Contexts";
import { useMedia } from "dsl/atoms/ResponsiveMedia";
import { useCareseekerNavigationHandlers } from "dsl/hooks";
import isEqual from "lodash/isEqual";
import React, { CSSProperties, Fragment, useMemo } from "react";
import { useTracking } from "react-tracking";
import { useLoggedInAccount } from "reduxentities/selectors";
import styled from "styled-components";
import { useLocale, useTranslations } from "translations";
import Translations from "translations/types";
import { usePatientInfoContext, usePatientInfoSlug } from "./index";

export type EmptiableFieldProps = {
  bold?: boolean;
  category?: string;
  noEmptyValue?: boolean;
  oldValue?: string | null | undefined;
  prefix?: string | null | undefined;
  testId: string;
  title?: string;
  value: string | null | undefined;
  withDiff?: boolean;
};

export const isInfectionsAndGermsPredicamentNegative = (
  value: { infection_and_germs_state?: number } | null | undefined,
  translations: Translations,
): [boolean, string] => [
  !!value && value.infection_and_germs_state !== PREDICAMENT_STATE_YES,
  !!value && value.infection_and_germs_state === PREDICAMENT_STATE_UNKNOWN
    ? translations.general.unknown
    : translations.auctionRequest.noGermsInfections,
];

const ContentWrapper = styled.div`
  display: flex;
`;

const PrefixWrapper = styled.span<{ bold?: boolean }>`
  margin-right: ${dp(-12)};
  font-weight: ${(props) =>
    props.bold ? FONT_WEIGHT_BOLD : FONT_WEIGHT_REGULAR};
`;

const lockIconStyle: CSSProperties = {
  color: isNewTheme ? ICON_DARK : ICON_LIGHT_GREY,
  fontSize: isNewTheme ? FONT_SIZE_12 : FONT_SIZE_18,
  height: isNewTheme ? dp(12) : undefined,
  width: isNewTheme ? dp(12) : undefined,
};

const Prefix = ({
  bold,
  text,
  translations,
}: {
  bold: boolean;
  text: string | null | undefined;
  translations: Translations;
}) => (
  <PrefixWrapper bold={bold}>
    {`${text}${translations.general.colon} `}
  </PrefixWrapper>
);

export const CategoryContainer = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  @media print {
    flex-direction: row;
    align-items: baseline;
    flex-wrap: wrap;
    page-break-inside: avoid;
  }
  max-width: ${(props) => (props.fullWidth ? "unset" : "450px")};
  @media print {
    max-width: 100%;
  }
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0%;
`;

type Value = boolean | number | string;
type FieldValue = Array<number> | Value;

export type ContentWrapperType = (
  content: React.ReactNode | null | undefined,
) => JSX.Element;
const defaultWrapper: ContentWrapperType = (content) => <>{content}</>;

const sortAscending = (a: number, b: number) => a - b;

function isSameValue(
  value1: FieldValue | null | undefined,
  value2: FieldValue | null | undefined,
): boolean {
  if (Array.isArray(value1) && Array.isArray(value2)) {
    const array1 = [...value1].sort(sortAscending);
    const array2 = [...value2].sort(sortAscending);
    return isEqual(array1, array2);
  }
  return value1 === value2;
}

export const blurStyle = {
  filter: "blur(0.3rem)",
  background: "white",
  userSelect: "none",
} as const;

export const italic = {
  color: STATUS_BADGE_MEDIUM_GREY,
  fontStyle: "Italic",
};

type ComputeDiffType = "array" | "boolean" | "int" | "number" | "string";
const DIFF_TYPE_STRING: ComputeDiffType = "string";
const DIFF_TYPE_BOOLEAN: ComputeDiffType = "boolean";
const DIFF_TYPE_ARRAY: ComputeDiffType = "array";
const DIFF_TYPE_NUMBER: ComputeDiffType = "number";

function withDeletedString(
  v: FieldValue | null | undefined,
  v2: FieldValue | null | undefined,
): boolean {
  if (typeof v === "string" && typeof v2 === "string") {
    return v === "" && v2.length > 0;
  }
  return false;
}

function computeDiff(
  value: FieldValue | null | undefined,
  oldValue: FieldValue | null | undefined,
  type: ComputeDiffType,
): {
  displayValue: FieldValue;
  style: AnyObject;
  type: PatientDiffType;
} | null {
  switch (type) {
    case DIFF_TYPE_STRING:
    case DIFF_TYPE_ARRAY:
    case DIFF_TYPE_NUMBER: {
      if (value != null && oldValue == null)
        return {
          style: POSITIVE_DIFF_STYLE,
          displayValue: value,
          type: POSITIVE_DIFF,
        };
      if (
        value != null &&
        oldValue != null &&
        !withDeletedString(value, oldValue) &&
        !isSameValue(value, oldValue)
      )
        return {
          style: POSITIVE_DIFF_STYLE,
          displayValue: value,
          type: SIMPLE_DIFF,
        };

      if (
        withDeletedString(value, oldValue) ||
        (value == null && oldValue != null)
      )
        return {
          style: NEGATIVE_DIFF_STYLE,
          displayValue: oldValue || "",
          type: NEGATIVE_DIFF,
        };

      return null;
    }
    case DIFF_TYPE_BOOLEAN: {
      if ((value != null && oldValue == null) || (value && !oldValue))
        return {
          style: POSITIVE_DIFF_STYLE,
          displayValue: value,
          type: POSITIVE_DIFF,
        };

      if ((value == null && oldValue != null) || (!value && oldValue))
        return {
          style: NEGATIVE_DIFF_STYLE,
          displayValue: oldValue,
          type: NEGATIVE_DIFF,
        };

      return null;
    }
    default:
      return null;
  }
}

export function isNumberAndNotZero(value: number | undefined): boolean {
  return value != null && value !== 0;
}
export const isNotEmptyString = (value: string | null | undefined): boolean =>
  value != null && value.length > 0;
export const stringFilled = (value: string | null | undefined) => value != null;
export const descriptionCategory = (
  value: { description?: string } | null | undefined,
) => value != null && isNotEmptyString(value.description);

export const BodyWrapper = styled(Body)<{ column?: boolean }>`
  display: flex;
  flex-direction: ${({ column }) => (column ? "column" : "row")};
`;

function isNullValue(
  value: boolean | number | string | null | undefined,
  noEmpty?: boolean,
) {
  switch (typeof value) {
    case "string":
      return value === null || (noEmpty && value === "");
    case "boolean":
      return value === null;
    case "number":
      return !value;
    default:
      return true;
  }
}

export const ChipContainer = styled.div`
  margin: ${margin(0.5)};
`;

const getKey = (value: string, prefix: string | null | undefined) =>
  `${prefix || ""}-${value}`;

function computeChipProps(props: {
  bold?: boolean;
  disabled?: boolean;
  primary?: boolean;
  selected?: boolean;
}) {
  if (props.disabled) {
    if (props.selected) return { disabled: true, bold: true };
    return { bold: true };
  }
  return props;
}

function generatePrefixedString(
  translations: Translations,
  str: string,
  prefix: string | null | undefined,
  suffix?: string | null | undefined,
) {
  const strValue =
    suffix && isNotEmptyString(suffix) ? `${str} ${suffix}` : str;
  return prefix && isNotEmptyString(prefix)
    ? `${prefix}${translations.general.colon} ${strValue}`
    : strValue;
}

function ChipValue({
  chipProps,
  keyPrefix,
  prefix,
  style,
  suffix,
  textBreak,
  value,
}: {
  chipProps: {
    bold?: boolean;
    disabled?: boolean;
    primary?: boolean;
    selected?: boolean;
  };
  keyPrefix?: string;
  prefix?: string;
  style?: React.CSSProperties;
  suffix?: string;
  textBreak?: boolean;
  value: string;
}) {
  const print = usePrint();
  const translations = useTranslations();

  const CustomChip = print ? PrintableChip : Chip;
  const chipStyle =
    style != null
      ? {
          primary: true,
          selected: false,
          backgroundColor: style.backgroundColor,
          opacity: 0.9,
        }
      : {};
  const props = {
    ...computeChipProps(chipProps),
    ...chipStyle,
    textBreak,
  };

  return (
    <ChipContainer key={getKey(value, keyPrefix)} style={style}>
      <CustomChip {...props}>
        {style != null ? (
          <ChipText
            primary
            bold
            textBreak={textBreak}
            dark
            style={{
              textDecoration: style.textDecoration,
              margin: margin(0),
            }}
          >
            {generatePrefixedString(translations, value, prefix, suffix)}
          </ChipText>
        ) : (
          generatePrefixedString(translations, value, prefix, suffix)
        )}
      </CustomChip>
    </ChipContainer>
  );
}

export function ArrayChip({
  bold,
  disabled,
  getOntology,
  keyPrefix,
  oldValue,
  primary,
  selected,
  type,
  value,
  withDiff,
}: {
  bold?: boolean;
  disabled?: boolean;
  getOntology: GetOntologyType;
  keyPrefix: string;
  oldValue?: Array<number> | null | undefined;
  primary?: boolean;
  selected?: boolean;
  type: OntologyType;
  value: Array<number> | null | undefined;
  withDiff?: boolean;
}) {
  const diff = withDiff && computeDiff(value, oldValue, DIFF_TYPE_ARRAY);
  const chipProps = { selected, primary, bold, disabled };

  if (diff) {
    const merged = [...(value || []), ...(oldValue || [])].filter(
      (v, i, arr) => arr.indexOf(v) === i,
    );

    return (
      <>
        {merged.map((v) => (
          <OntologyChip
            getOntology={getOntology}
            key={getKey(v.toString(), keyPrefix)}
            type={type}
            value={value?.includes(v) ? v : null}
            oldValue={oldValue?.includes(v) ? v : null}
            keyPrefix={keyPrefix}
            withDiff
            {...chipProps}
          />
        ))}
      </>
    );
  }

  if (!value || !Array.isArray(value)) return null;
  return (
    <>
      {value.map((key) => (
        <ChipValue
          key={getKey(key.toString(), keyPrefix)}
          value={getOntology({ type, key })}
          keyPrefix={keyPrefix}
          chipProps={chipProps}
        />
      ))}
    </>
  );
}

export function NumberChip({
  bold,
  disabled,
  keyPrefix,
  oldValue,
  prefix,
  primary,
  selected,
  transform,
  value,
  withDiff,
}: {
  bold?: boolean;
  disabled?: boolean;
  keyPrefix: string;
  oldValue?: number | null | undefined;
  prefix?: string;
  primary?: boolean;
  selected?: boolean;
  transform: (value: number) => string;
  value: number | null | undefined;
  withDiff?: boolean;
}) {
  const diff = withDiff && computeDiff(value, oldValue, DIFF_TYPE_NUMBER);
  const chipProps = { selected, primary, bold, disabled };

  if (diff) {
    const { displayValue, style } = diff;
    return typeof displayValue === "number" ? (
      <ChipValue
        value={transform(displayValue)}
        prefix={prefix}
        keyPrefix={keyPrefix}
        chipProps={chipProps}
        style={style}
      />
    ) : null;
  }

  if (value == null) return null;
  return (
    <ChipValue
      value={transform(value)}
      prefix={prefix}
      keyPrefix={keyPrefix}
      chipProps={chipProps}
    />
  );
}

export function OntologyChip({
  bold,
  disabled,
  getOntology,
  keyPrefix,
  oldValue,
  primary,
  selected,
  type,
  value,
  withDiff,
}: {
  bold?: boolean;
  disabled?: boolean;
  getOntology: GetOntologyType;
  keyPrefix: string;
  oldValue?: boolean | number | null | undefined;
  primary?: boolean;
  selected?: boolean;
  type: OntologyType;
  value: boolean | number | null | undefined;
  withDiff?: boolean;
}) {
  const diff = withDiff && computeDiff(value, oldValue, DIFF_TYPE_NUMBER);
  const chipProps = { selected, primary, bold, disabled };

  if (diff) {
    const { displayValue, style } = diff;
    return typeof displayValue === "number" ? (
      <ChipValue
        value={getOntology({ type, key: displayValue })}
        keyPrefix={keyPrefix}
        chipProps={chipProps}
        style={style}
      />
    ) : null;
  }

  if (!value || typeof value !== "number") return null;
  return (
    <ChipValue
      value={getOntology({ type, key: value })}
      keyPrefix={keyPrefix}
      chipProps={chipProps}
    />
  );
}

export function BooleanChip({
  bold,
  disabled,
  keyPrefix,
  label,
  oldValue,
  prefix,
  primary,
  selected,
  value,
  withDiff,
}: {
  bold?: boolean;
  disabled?: boolean;
  keyPrefix: string;
  label: string | { oldValue: string; value: string };
  oldValue?: boolean;
  prefix?: string;
  primary?: boolean;
  selected?: boolean;
  value: boolean;
  withDiff?: boolean;
}) {
  const diff = withDiff && computeDiff(value, oldValue, DIFF_TYPE_BOOLEAN);
  const chipProps = { selected, primary, bold, disabled };

  if (diff) {
    const { style, type } = diff;
    const valueLabel =
      typeof label === "string"
        ? label
        : type === POSITIVE_DIFF
        ? label.value
        : label.oldValue;
    return (
      <ChipValue
        value={valueLabel}
        prefix={prefix}
        keyPrefix={keyPrefix}
        chipProps={chipProps}
        style={style}
      />
    );
  }

  if (!value) return null;
  const valueLabel = typeof label === "string" ? label : label.value;
  return (
    <ChipValue
      value={valueLabel}
      prefix={prefix}
      keyPrefix={keyPrefix}
      chipProps={chipProps}
    />
  );
}

export function StringChip({
  bold,
  disabled,
  keyPrefix,
  oldValue,
  prefix,
  primary,
  selected,
  suffix,
  textBreak,
  value,
  withDiff,
}: {
  bold?: boolean;
  disabled?: boolean;
  keyPrefix: string;
  oldValue?: string | null | undefined;
  prefix?: string;
  primary?: boolean;
  selected?: boolean;
  suffix?: string;
  textBreak?: boolean;
  value: string | null | undefined;
  withDiff?: boolean;
}) {
  const diff = withDiff && computeDiff(value, oldValue, DIFF_TYPE_STRING);
  const chipProps = { selected, primary, bold, disabled };

  if (diff) {
    const { displayValue, style } = diff;
    return typeof displayValue === "string" ? (
      <ChipValue
        value={displayValue}
        suffix={suffix}
        prefix={prefix}
        keyPrefix={keyPrefix}
        chipProps={chipProps}
        style={style}
        textBreak={textBreak}
      />
    ) : null;
  }

  if (!value) return null;
  return (
    <ChipValue
      value={value}
      prefix={prefix}
      suffix={suffix}
      keyPrefix={keyPrefix}
      chipProps={chipProps}
      textBreak={textBreak}
    />
  );
}

function FieldTitle({
  oldValue,
  title,
  value,
  withDiff,
}: {
  oldValue: boolean;
  title: string;
  value: boolean;
  withDiff: boolean | null | undefined;
}): JSX.Element {
  if (!withDiff) return <>{title}</>;
  const diff = computeDiff(value, oldValue, DIFF_TYPE_BOOLEAN);

  return diff != null ? (
    <div style={{ display: "flex" }}>
      <span style={diff.style}>{title}</span>
    </div>
  ) : (
    <>{title}</>
  );
}

export function EmptiableField({
  bold,
  category,
  noEmptyValue,
  oldValue,
  prefix,
  testId,
  title,
  value,
  withDiff,
}: EmptiableFieldProps) {
  const translations = useTranslations();

  if (
    withDiff
      ? isNullValue(value, noEmptyValue) && isNullValue(oldValue, noEmptyValue)
      : isNullValue(value, noEmptyValue)
  )
    return null;

  return category != null ? (
    <CategoryContainer className={category} data-testid={testId}>
      {title && (
        <Subheading bold>
          <FieldTitle
            title={title}
            value={value != null}
            oldValue={oldValue != null}
            withDiff={withDiff}
          />
        </Subheading>
      )}
      <CategoryDescription
        value={value}
        oldValue={oldValue}
        withDiff={withDiff}
        noEmptyValue={noEmptyValue}
        title={title}
      />
    </CategoryContainer>
  ) : (
    <HorizontalLayout flexWrap="wrap" data-testid={testId} maxWidth="100%">
      {title && (
        <CutMargin>
          <Body
            data-testid="title"
            maxWidth={sizing(45)}
            fontWeight={bold ? FONT_WEIGHT_BOLD : FONT_WEIGHT_REGULAR}
          >
            <FieldTitle
              title={`${title}${translations.general.colon} `}
              value={value != null}
              oldValue={oldValue != null}
              withDiff={withDiff}
            />
          </Body>
        </CutMargin>
      )}
      <CategoryDescription
        value={value}
        prefix={prefix}
        oldValue={oldValue}
        withDiff={withDiff}
        noEmptyValue={noEmptyValue}
        title={title}
      />
    </HorizontalLayout>
  );
}

export function CategoryDescription({
  noEmptyValue,
  oldValue,
  prefix,
  title,
  value,
  withDiff,
}: {
  noEmptyValue?: boolean;
  oldValue?: string | null | undefined;
  prefix?: string | null | undefined;
  title?: string;
  value: string | null | undefined;
  withDiff?: boolean;
}) {
  if (
    withDiff
      ? isNullValue(value, noEmptyValue) && isNullValue(oldValue, noEmptyValue)
      : isNullValue(value, noEmptyValue)
  )
    return null;

  const diff = computeDiff(value, oldValue, DIFF_TYPE_STRING);
  if (withDiff && diff && diff.displayValue) {
    return (
      <StringField
        testId="description"
        prefix={prefix}
        value={value}
        oldValue={oldValue}
        withDiff={withDiff}
      />
    );
  }

  if (isNotEmptyString(value))
    return <StringField testId="description" prefix={prefix} value={value} />;

  return (
    <InfoText
      title={title}
      prefix={prefix}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    />
  );
}

function OntologyValue({
  getOntology,
  label,
  style,
  type,
  value,
  verticalLayout = false,
}: {
  getOntology: GetOntologyType;
  label?: string;
  style?: React.CSSProperties;
  type: OntologyType;
  value?: Array<number> | number;
  verticalLayout: boolean;
}) {
  if (!value) return null;

  if (Array.isArray(value)) {
    const values = verticalLayout
      ? value.map((key) => <div key={key}>{getOntology({ type, key })}</div>)
      : value.map((key) => getOntology({ type, key })).join(", ");
    return (
      <ContentWrapper>
        <Body style={style}>{values}</Body>
      </ContentWrapper>
    );
  }

  const wrappedContent = (
    <ContentWrapper>
      <Body style={style}>{getOntology({ type, key: value })}</Body>
    </ContentWrapper>
  );

  return label ? (
    <Category title={label} category={type}>
      {wrappedContent}
    </Category>
  ) : (
    wrappedContent
  );
}

export function OntologyField({
  label,
  value,
  oldValue,
  withDiff,
  type,
  wrap = defaultWrapper,
  verticalLayout = false,
  getOntology,
}: {
  getOntology: GetOntologyType;
  label?: string;
  oldValue?: Array<number> | number | null | undefined;
  type: OntologyType;
  value: Array<number> | number | null | undefined;
  verticalLayout?: boolean;
  withDiff?: boolean;
  wrap?: ContentWrapperType;
}) {
  const diff = withDiff && computeDiff(value, oldValue, DIFF_TYPE_NUMBER);
  if (diff) {
    const { displayValue, style, type: PatientDiffType } = diff;
    if (
      PatientDiffType === SIMPLE_DIFF &&
      value != null &&
      (typeof value === "number" || Array.isArray(value)) &&
      oldValue != null &&
      (typeof oldValue === "number" || Array.isArray(oldValue))
    ) {
      const diff = (
        <VerticalLayout>
          <OntologyValue
            getOntology={getOntology}
            value={oldValue}
            type={type}
            verticalLayout={verticalLayout}
            style={NEGATIVE_DIFF_STYLE}
          />
          <OntologyValue
            getOntology={getOntology}
            value={value}
            type={type}
            verticalLayout={verticalLayout}
            style={POSITIVE_DIFF_STYLE}
          />
        </VerticalLayout>
      );

      return wrap(
        label ? (
          <Category title={label} category={type}>
            {diff}
          </Category>
        ) : (
          diff
        ),
      );
    }

    return displayValue != null &&
      (typeof displayValue === "number" || Array.isArray(displayValue))
      ? wrap(
          <OntologyValue
            getOntology={getOntology}
            label={label}
            value={displayValue}
            type={type}
            verticalLayout={verticalLayout}
            style={style}
          />,
        )
      : null;
  }

  if (!value) return null;

  return wrap(
    <OntologyValue
      getOntology={getOntology}
      label={label}
      value={value}
      type={type}
      verticalLayout={verticalLayout}
    />,
  );
}

export function BooleanField({
  value,
  oldValue,
  withDiff,
  label,
  wrap = defaultWrapper,
}: {
  label: string;
  oldValue?: boolean | null | undefined;
  value: boolean | null | undefined;
  withDiff?: boolean;
  wrap?: ContentWrapperType;
}) {
  const diff = withDiff && computeDiff(value, oldValue, DIFF_TYPE_BOOLEAN);
  if (diff)
    return wrap(
      <ContentWrapper>
        <Body style={diff.style}>{label}</Body>
      </ContentWrapper>,
    );

  if (!value) return null;
  return wrap(<Body>{label}</Body>);
}

export const LocationContent = ({
  bold = true,
  location,
  oldLocation,
  prefixText,
  translations,
  withDiff,
}: {
  bold?: boolean;
  location: Location | null | undefined;
  oldLocation?: Location | null | undefined;
  prefixText?: string | null | undefined;
  translations: Translations;
  withDiff?: boolean;
}) => {
  const encryptedData = location?.encrypted_house_number;
  const decrypted = location?.encrypted_house_number?.decrypted;
  const blurred = encryptedData && !decrypted;

  return (
    <StringField
      testId="encrypted_house_number"
      contentMargin={blurred ? margin(0, 0, 0, 2) : undefined}
      value={location && formatLocation({ location, translations })}
      oldValue={
        oldLocation && formatLocation({ location: oldLocation, translations })
      }
      withDiff={withDiff}
      wrap={(content) => (
        <BodyWrapper whiteSpace="normal">
          <Prefix
            bold={bold}
            text={prefixText || translations.address.address}
            translations={translations}
          />
          <HorizontalLayout>
            {content}
            {blurred && (
              <>
                <StringValue
                  testId="encrypted_house_number"
                  value="Blur"
                  style={{ ...blurStyle, margin: margin(0, 2, 0, 0.5) }}
                />
                <Tooltip
                  title={translations.auctionRequest.accessEncryptedDataHint}
                >
                  <LockIcon
                    style={{
                      ...lockIconStyle,
                      transform: translate({ y: 2 }),
                    }}
                    size={lockIconStyle.fontSize}
                  />
                </Tooltip>
              </>
            )}
            {decrypted && (
              <LockIcon
                style={{
                  ...lockIconStyle,
                  transform: translate({ y: 2 }),
                }}
                size={lockIconStyle.fontSize}
              />
            )}
          </HorizontalLayout>
        </BodyWrapper>
      )}
    />
  );
};

export const LocationContentSection = ({
  bold,
  location,
  oldLocation,
  title,
  translations,
  withDiff,
}: {
  bold?: boolean;
  location: Location | null | undefined;
  oldLocation?: Location | null | undefined;
  title?: string | null | undefined;
  translations: Translations;
  withDiff?: boolean;
}) => {
  return (
    <LocationContent
      location={location}
      oldLocation={oldLocation}
      withDiff={withDiff}
      translations={translations}
      prefixText={title}
      bold={bold}
    />
  );
};

export function StringValue({
  multiLine,
  prefix,
  style,
  testId,
  value,
}: {
  multiLine?: boolean | null | undefined;
  prefix?: string | null | undefined;
  style?: ToType | null | undefined;
  testId: string;
  value: string;
}) {
  const translations = useTranslations();
  const content = generatePrefixedString(translations, value, prefix);

  return (
    <ContentWrapper style={{ flexDirection: "column" }}>
      <Body data-testid={testId} style={style} whiteSpace="pre-line">
        {multiLine ? renderMultiLine(content) : content}
      </Body>
    </ContentWrapper>
  );
}

export function StringField({
  value,
  oldValue,
  withDiff,
  prefix,
  testId,
  multiLine,
  contentMargin,
  wrap = defaultWrapper,
}: {
  contentMargin?: string;
  multiLine?: boolean;
  oldValue?: string | null | undefined;
  prefix?: string | null | undefined;
  testId: string;
  value: string | null | undefined;
  withDiff?: boolean;
  wrap?: ContentWrapperType;
}) {
  const diff = withDiff && computeDiff(value, oldValue, DIFF_TYPE_STRING);
  if (diff) {
    const { displayValue, style, type } = diff;
    if (
      type === SIMPLE_DIFF &&
      typeof value === "string" &&
      typeof oldValue === "string"
    )
      return wrap(
        <VerticalLayout>
          <StringValue
            multiLine={multiLine}
            style={NEGATIVE_DIFF_STYLE}
            value={oldValue}
            prefix={prefix}
            testId={testId}
          />
          <StringValue
            multiLine={multiLine}
            style={POSITIVE_DIFF_STYLE}
            value={value}
            prefix={prefix}
            testId={testId}
          />
        </VerticalLayout>,
      );

    if (typeof displayValue !== "string") return null;
    return wrap(
      <StringValue
        multiLine={multiLine}
        style={style}
        value={displayValue}
        prefix={prefix}
        testId={testId}
      />,
    );
  }

  if (!value) return null;

  return wrap(
    <StringValue
      multiLine={multiLine}
      value={value}
      style={{ margin: contentMargin }}
      prefix={prefix}
      testId={testId}
    />,
  );
}

export function EncryptedField({
  bold = false,
  hideEmpty,
  isSealdOnly,
  longContext,
  prefix,
  suffix,
  testId,
  value,
  verticalLayout = false,
  withDateString,
  withEncryptionAccess,
}: {
  bold?: boolean;
  hideEmpty?: boolean;
  isSealdOnly: boolean;
  longContext?: boolean;
  prefix?: string | null | undefined;
  suffix?: string | null | undefined;
  testId: string;
  value: EncryptedValue | null | undefined;
  verticalLayout?: boolean;
  withDateString?: boolean;
  withEncryptionAccess: boolean;
}) {
  const translations = useTranslations();
  const locale = useLocale();
  const decryptedValue = getEncryptedValue(value);

  if (withEncryptionAccess && !value) {
    if (hideEmpty) return null;

    return (
      <BodyWrapper>
        <HorizontalLayout aligned maxWidth="100%">
          <Prefix bold={bold} text={prefix} translations={translations} />
          <StringValue
            testId={testId}
            value={translations.actions.valueEmtpy}
            style={italic}
          />
          <LockIcon style={lockIconStyle} size={lockIconStyle.fontSize} />
        </HorizontalLayout>
      </BodyWrapper>
    );
  }

  const withEncryptedValue = isSealdOnly
    ? value?.seald_content
    : value?.content;
  if (withEncryptedValue) {
    if (value && !decryptedValue) {
      return verticalLayout ? (
        <BodyWrapper whiteSpace="nowrap" margin={margin(0, 0, 0, 2)}>
          <VerticalLayout maxWidth="100%">
            <HorizontalLayout>
              <Prefix bold={bold} text={prefix} translations={translations} />
              <LockSecurePin
                tooltip={translations.auctionRequest.accessEncryptedDataHint}
                style={{ ...lockIconStyle, margin: margin(0.4, 0, 0, 2) }}
              />
            </HorizontalLayout>
            <StringValue
              testId={testId}
              value="Blurred Data"
              style={{ ...blurStyle, margin: margin(0, 0.75) }}
            />
          </VerticalLayout>
        </BodyWrapper>
      ) : (
        <BodyWrapper>
          <HorizontalLayout aligned maxWidth="100%">
            <Prefix bold={bold} text={prefix} translations={translations} />
            <StringValue
              value="Blurred Data"
              style={blurStyle}
              testId={testId}
            />
            <Tooltip
              title={translations.auctionRequest.accessEncryptedDataHint}
            >
              <LockIcon style={lockIconStyle} size={lockIconStyle.fontSize} />
            </Tooltip>
          </HorizontalLayout>
        </BodyWrapper>
      );
    }
  }
  if (decryptedValue) {
    const content = generatePrefixedString(
      translations,
      decryptedValue,
      prefix,
      suffix,
    );
    if (longContext) {
      return (
        <ContentWrapper>
          <Body>
            {content}
            <LockIcon
              style={{ ...lockIconStyle, margin: margin(0, 2, -0.5, 2) }}
              size={lockIconStyle.fontSize}
            />
          </Body>
        </ContentWrapper>
      );
    }
    const formattedValue = withDateString
      ? convertBirthDateIn(value, { locale }) || ""
      : decryptedValue;

    if (verticalLayout) {
      return (
        <BodyWrapper>
          <VerticalLayout maxWidth="100%">
            <HorizontalLayout aligned>
              <Prefix bold={bold} text={prefix} translations={translations} />
              <LockIcon
                style={{ ...lockIconStyle, margin: margin(0, 0, 0, 2) }}
                size={lockIconStyle.fontSize}
              />
            </HorizontalLayout>
            <StringValue
              testId={testId}
              value={
                suffix ? formattedValue.concat(` ${suffix}`) : formattedValue
              }
              style={{ margin: margin(0) }}
            />
            <br />
          </VerticalLayout>
        </BodyWrapper>
      );
    }
    return (
      <BodyWrapper>
        <HorizontalLayout aligned maxWidth="100%">
          <Prefix bold={bold} text={prefix} translations={translations} />
          <StringValue
            testId={testId}
            value={
              suffix ? formattedValue.concat(` ${suffix}`) : formattedValue
            }
          />
          <LockIcon style={lockIconStyle} size={lockIconStyle.fontSize} />
        </HorizontalLayout>
      </BodyWrapper>
    );
  }
  return null;
}

export function EmptiableCategory({
  category,
  children,
  empty,
  hasEmptyField,
  oldValue,
  title,
  value,
  withDiff,
}: {
  category?: string;
  children?: React.ReactNode;
  empty?: boolean;
  hasEmptyField?: boolean;
  oldValue?: AnyObject | string | null | undefined;
  title: string;
  value?: AnyObject | string | null | undefined;
  withDiff?: boolean;
}) {
  return (
    <Category
      category={category}
      title={
        <FieldTitle
          title={title}
          value={value != null}
          oldValue={oldValue != null}
          withDiff={withDiff}
        />
      }
    >
      {empty ? (
        <InfoText
          title={title}
          value={value}
          oldValue={oldValue}
          withDiff={withDiff}
        />
      ) : (
        children
      )}
      {hasEmptyField && <InfoText />}
    </Category>
  );
}

function InfoText({
  oldValue,
  prefix,
  title = "",
  value,
  withDiff,
}: {
  oldValue?: AnyObject | string | null | undefined;
  prefix?: string | null | undefined;
  title?: string;
  value?: AnyObject | string | null | undefined;
  withDiff?: boolean | null | undefined;
}) {
  const translations = useTranslations();
  const { trackEvent } = useTracking();

  const { patient: patientNavigation } = useCareseekerNavigationHandlers();
  const { auction, isClinicApp, withEdit } = usePatientInfoContext();
  const { assessmentSlug, auctionId, patientId } = usePatientInfoSlug();
  const diff = withDiff
    ? computeDiff(value != null, oldValue != null, DIFF_TYPE_BOOLEAN)
    : undefined;
  const editSearchAction = useMemo(
    () => auction && findSearchAction(auction, SEARCH_ACTION_EDIT_SEARCH),
    [auction?.search_actions],
  );

  if (!withEdit)
    return (
      <Body>
        <div style={{ display: "flex" }}>
          <span style={diff?.style || {}}>
            {translations.auctionRequest.noAdditionalInformationProvided}
          </span>
        </div>
      </Body>
    );

  if (isClinicApp && editSearchAction)
    return (
      <Body
        data-testid={`add-missing-${title.replace(/ /g, "-").toLowerCase()}`}
        primary
        fontWeight="bold"
        style={{ cursor: "pointer" }}
        onClick={() => {
          trackEvent({
            name: TRACK_EVENTS.MISSING_FIELD,
            page: assessmentSlug,
          });

          patientNavigation.goToassessmentSlug({
            patientId,
            auctionId,
            assessmentSlug,
          });
        }}
      >
        {prefix && (
          <Body data-testid="description" display="inline" margin={margin(0)}>
            {`${prefix}${translations.general.colon} `}
          </Body>
        )}
        {translations.auctionResponse.areYouSureNotAddInfo}
      </Body>
    );

  return null;
}

export function Category({
  category,
  children,
  fullWidth,
  title,
}: {
  category?: string;
  children?: React.ReactNode;
  fullWidth?: boolean;
  title: React.ReactNode | string | null | undefined;
}) {
  return (
    <CategoryContainer className={category} fullWidth={fullWidth}>
      <Subheading bold>{title}</Subheading>
      {children}
    </CategoryContainer>
  );
}

const CutMargin = styled.span`
  margin-right: -28px;
`;

// Accepts object and array of keys to check
export const inputCollectionIsNotEmpty = ({
  assertionObject,
  keysToExist,
}: {
  assertionObject?: AnyObject;
  keysToExist: Array<string>;
}): boolean => {
  if (!assertionObject || !keysToExist) return false;
  const exists = keysToExist.some((key) => {
    const chunk = assertionObject[key];
    return (
      chunk !== undefined && chunk !== null && chunk !== false && chunk !== 0
    );
  });

  return exists;
};

export const activableInputCollectionIsNotEmpty = (
  collection: AnyObject,
): boolean => {
  const values = collection && Object.values(collection);
  if (values) {
    values.pop();
  }
  return !!(
    Array.isArray(values) && values.filter((value) => value !== null).length
  );
};

export const exists = (categories: Array<any>) => {
  // eslint-disable-next-line  no-plusplus
  for (let i = 0; i < categories.length; i++) {
    if (categories[i].exists) return true;
  }

  return false;
};

export type ValueGetter = (auction: Auction | null | undefined) => any;

export type CategoryType = {
  Component: any;
  exists: boolean | null | undefined;
  fullWidth?: boolean;
  key: string;
  notEmpty?: boolean | null | undefined;
  valueGetter: ValueGetter;
};

const createCategories = (
  chunks: Array<CategoryType>,
  props: {
    auction: Auction;
    forClinic: boolean | null | undefined;
    getOntology: GetOntologyType;
    isSealdOnly: boolean;
    oldAuction?: Auction;
    translations: Translations;
    withEncryptionAccess: boolean;
  },
) => {
  return chunks.map((chunk) =>
    React.createElement(chunk.Component, {
      key: chunk.key,
      value: chunk.valueGetter(props.auction),
      oldValue: chunk.valueGetter(props?.oldAuction),
      withDiff: props?.oldAuction != null,
      empty: !chunk.notEmpty,
      ...props,
    }),
  );
};

export function generateCategoryArray(
  array: Array<CategoryType>,
  chunkSize: number,
) {
  let i = 0;
  return array.reduce((chunks: Array<ToType>, current: ToType) => {
    if (current.fullWidth) {
      i = chunkSize;
      return [...chunks, [current]];
    }

    i += 1;
    if ((i - 1) % chunkSize === 0) return [...chunks, [current]];

    const lastChunk = chunks.pop() || [];
    return [...chunks, [...lastChunk, current]];
  }, []);
}

const LayoutWrapper = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const { isDesktop } = useMedia();
  return isDesktop ? (
    <HorizontalLayout>{children}</HorizontalLayout>
  ) : (
    <VerticalLayout>{children}</VerticalLayout>
  );
};

export function Categories({
  auction,
  categories,
  forClinic,
  getOntology,
  oldAuction,
  translations,
}: {
  auction: Auction;
  categories: Array<CategoryType>;
  forClinic?: boolean;
  getOntology: GetOntologyType;
  oldAuction?: Auction;
  translations: Translations;
}): ToType {
  const account = useLoggedInAccount();
  const isSealdOnly = useIsSealdOnly().isSealdOnly({
    oldSession: auction.patient?.session_key_context?.has_session_keys,
    newSealdSession: auction.patient?.seald_encryption_context?.seald_id,
  });
  const withEncryptionAccess = isSealdOnly
    ? auction.patient?.seald_encryption_context != null
    : getPatientSessionKey(account, auction.patient) != null;

  const defaultProps = {
    translations,
    forClinic,
    isSealdOnly,
    withEncryptionAccess,
    getOntology,
    auction,
    oldAuction,
  };

  return generateCategoryArray(
    categories.filter((chunk) => chunk.exists),
    2,
  ).map((categoryChunks: Array<CategoryType>) => (
    <Fragment
      key={`${categoryChunks[0].key}-${
        categoryChunks[1] ? categoryChunks[1].key : "upper_key"
      }`}
    >
      <LayoutWrapper>
        {createCategories(categoryChunks, defaultProps)}
      </LayoutWrapper>
    </Fragment>
  ));
}

function PrintCard({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}) {
  return (
    <div style={{ padding: padding(2, 3) }}>
      <Title>{title}</Title>
      <VerticalLayout>{children}</VerticalLayout>
    </div>
  );
}

export function CareproviderInfoCard({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}) {
  const print = usePrint();
  if (print) return <PrintCard title={title}>{children}</PrintCard>;

  return (
    <InfoCard title={title} cardMargin={margin(0, 0, 2, 0)}>
      <CardContentContainer padding={padding(2, 3)}>
        <VerticalLayout>{children}</VerticalLayout>
      </CardContentContainer>
    </InfoCard>
  );
}

export function ClinicInfoCard({
  children,
  noDivider,
  title,
}: {
  children: React.ReactNode;
  noDivider?: boolean;
  title?: string;
}) {
  return (
    <InfoCardLite noDivider={noDivider} cardMargin={margin(0)} title={title}>
      <VerticalLayout>{children}</VerticalLayout>
    </InfoCardLite>
  );
}

export const isWhitelisted = (
  whitelist: PatientWhitelistDefinition,
  auction: Auction | null | undefined,
  oldAuction: Auction | null | undefined,
): boolean => {
  return (
    (auction &&
      descriptiveWhitelist(whitelist)({
        formInputValue: auction,
        careseeker: auction.patient.careseeker,
      })) ||
    (oldAuction &&
      descriptiveWhitelist(whitelist)({
        formInputValue: oldAuction,
        careseeker: oldAuction.patient.careseeker,
      })) ||
    false
  );
};
