import { Paper, styled } from "@mui/material";
import { formatIndependenceScaleDateAndScore } from "core/model/utils/dates";
import {
  Auction,
  BarthelAssessment,
  BarthelIndex,
  EarlyRehabAssessment,
  Patient,
  RehabIndex,
  SingerAssessment,
} from "core/types";
import locale from "date-fns/locale/af";
import { GREY_50, WHITE } from "ds_legacy/materials/colors";
import { VerticalLayout } from "ds_legacy/materials/layouts";
import {
  APP_BAR_HEIGHT,
  RESPONSIVE_BREAKPOINT_TABLET,
  dp,
  margin,
  padding,
} from "ds_legacy/materials/metrics";
import {
  Body,
  FONT_SIZE_18,
  FONT_WEIGHT_BOLD,
  LINE_HEIGHT_24,
  Title,
} from "ds_legacy/materials/typography";
import { usePrint } from "dsl/atoms/Contexts";
import { usePrintWindow } from "dsl/atoms/PrintButton";
import { useMedia } from "dsl/atoms/ResponsiveMedia";
import { useOnEnter } from "dsl/atoms/Routes";
import { ReturnBar } from "dsl/organisms/BarthelIndex/shared";
import { calculateScore } from "dsl/organisms/IndependenceScale/shared";
import { ReactNode } from "react";

const ContentWrapper = styled(VerticalLayout)`
  @media print {
    @page {
      size: A4 portrait;
      margin: 20mm 10mm 20mm 20mm;
    }

    background: ${WHITE};
    break-after: avoid;
    max-width: 100%;
    page-break-inside: avoid;
  }
`;

export function convertToNewAssessment<T extends BarthelIndex | RehabIndex>(
  assessmentList: T[],
): T extends BarthelIndex ? BarthelAssessment : EarlyRehabAssessment {
  const firstAssessment = assessmentList[0];
  return {
    form: firstAssessment.form,
    score: calculateScore(firstAssessment.form as any),
    date: firstAssessment.date,
  } as T extends BarthelIndex ? BarthelAssessment : EarlyRehabAssessment;
}

export function IndependenceScaleSummaryPage({
  auctionId,
  children,
  patient,
}: {
  auctionId: Auction["id"];
  children?: ReactNode;
  patient: Patient;
}) {
  useOnEnter();
  usePrintWindow();

  const { isDesktop } = useMedia();
  const isPrint = usePrint();

  if (isPrint) {
    return <ContentWrapper>{children}</ContentWrapper>;
  }

  return (
    <div
      style={{
        background: GREY_50,
        boxSizing: "border-box",
        display: "flex",
        minHeight: `calc(100vh - ${dp(APP_BAR_HEIGHT)})`,
        padding: padding(0, 0, 4),
        width: "100%",
      }}
    >
      <Paper
        sx={{
          boxSizing: "border-box",
          margin: `${dp(isDesktop ? 16 : 0)} auto`,
          maxWidth: dp(RESPONSIVE_BREAKPOINT_TABLET),
          overflow: "visible",
          padding: padding(2, 3),
          width: "100%",
          background: WHITE,
        }}
      >
        <ReturnBar
          patient={patient}
          auctionId={auctionId}
          wrapperStyles={{ margin: margin(0, 0, 2) }}
          noBorder
        />
        <ContentWrapper>{children}</ContentWrapper>
      </Paper>
    </div>
  );
}

export function getSummaryPageTitle(patient: Patient) {
  return {
    patientName: `${patient.profile?.first_name?.decrypted ?? ""} ${
      patient.profile?.last_name?.decrypted ?? ""
    }`.trim(),
    recareId: patient.user_id ?? "",
  };
}

export function IndependenceScaleSummaryContent({
  assessmentData,
  auctionId,
  children,
  emptyDescription,
  patient,
  title,
}: {
  assessmentData?: SingerAssessment | BarthelAssessment | EarlyRehabAssessment;
  auctionId: Auction["id"];
  children: ReactNode;
  emptyDescription: string;
  patient: Patient;
  title: string;
}) {
  return (
    <IndependenceScaleSummaryPage auctionId={auctionId} patient={patient}>
      <Title
        style={{
          fontWeight: FONT_WEIGHT_BOLD,
          fontSize: FONT_SIZE_18,
          lineHeight: LINE_HEIGHT_24,
          margin: margin(0, 0, 2),
        }}
      >
        {title}
      </Title>
      {assessmentData?.date && assessmentData.form ? (
        children
      ) : assessmentData?.date && assessmentData.score ? (
        <Body margin={margin(0)} as="p">
          {formatIndependenceScaleDateAndScore(assessmentData, locale)}
        </Body>
      ) : (
        <Body margin={margin(0)} as="p">
          {emptyDescription}
        </Body>
      )}
    </IndependenceScaleSummaryPage>
  );
}
