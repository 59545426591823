import { ASSESSMENT_SLUG_NEEDS, SEARCH_TYPE_CARE } from "core/consts";
import {
  AccesDrainages as AccessDrainagesType,
  Auction,
  BaseCare as BaseCareType,
  Breathing as BreathingType,
  PatientNeeds as CareNeedsType,
  Companionship as CompanionshipType,
  CompressionCare as CompressionType,
  Dialysis as DialysisType,
  Food as FoodType,
  GetOntologyType,
  HelpAtHome as HelpAtHomeType,
  IncontinenceHelp as IncontinenceType,
  Injection as InjectionType,
  Medication as MedicationType,
  Palliative as PalliativeType,
  PatientNeedMonitoring,
  PatientNeedSupport,
  PsychiatricCare as PsychiatricCareType,
  WoundCare as WoundType,
} from "core/types";
import { useTranslations } from "translations";
import Translations from "translations/types";
import { PatientInfoSlugContext, usePatientInfoContext } from "./index";
import {
  BooleanField,
  Categories,
  CategoryDescription,
  EmptiableCategory,
  EmptiableField,
  StringField,
  ValueGetter,
  descriptionCategory,
  exists,
  stringFilled,
} from "./shared";

function Nutrition({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: FoodType | null | undefined;
  translations: Translations;
  value: FoodType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.nutrition}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <EmptiableField
        title={translations.patient.careNeeds.parenteralNutrition.label}
        testId="parenteral_nutrition"
        value={value?.parenteral_nutrition}
        oldValue={oldValue?.parenteral_nutrition}
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.careNeeds.enteralNutrition.label}
        testId="enteral_nutrition"
        value={value?.enteral_nutrition}
        oldValue={oldValue?.enteral_nutrition}
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}

function Incontinence({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: IncontinenceType | null | undefined;
  translations: Translations;
  value: IncontinenceType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.incontinence}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <EmptiableField
        title={translations.patient.careNeeds.urinaryIncontinence}
        testId="urinary_incontinence"
        value={value?.urinary_incontinence}
        oldValue={oldValue?.urinary_incontinence}
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.careNeeds.fecalIncontinence}
        testId="fecal_incontinence"
        value={value?.fecal_incontinence}
        oldValue={oldValue?.fecal_incontinence}
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}

function Breathing({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: BreathingType | null | undefined;
  translations: Translations;
  value: BreathingType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.breathing}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <EmptiableField
        title={translations.patient.careNeeds.tracheostomy.label}
        testId="tracheostomy"
        value={value?.tracheostomy}
        oldValue={oldValue?.tracheostomy}
        withDiff={withDiff}
      />
      <BooleanField
        value={value?.specialised_intensive_nurse}
        oldValue={oldValue?.specialised_intensive_nurse}
        label={translations.ontologies.service.values.specialisedIntensiveNurse}
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.careNeeds.oxygenTherapy.label}
        testId="oxygen_therapy"
        value={value?.oxygen_therapy}
        oldValue={oldValue?.oxygen_therapy}
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}

function Dialysis({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: DialysisType | null | undefined;
  translations: Translations;
  value: DialysisType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.dialysis}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <EmptiableField
        title={translations.patient.careNeeds.hemodialysis.label}
        testId="hemodialysis"
        value={value?.hemodialysis}
        oldValue={oldValue?.hemodialysis}
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.careNeeds.peritonealDialysis.label}
        testId="peritoneal_dialysis"
        value={value?.peritoneal_dialysis}
        oldValue={oldValue?.peritoneal_dialysis}
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}

function Wounds({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: WoundType | null | undefined;
  translations: Translations;
  value: WoundType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.woundCare}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <StringField
        testId="woundCare_description"
        value={value?.description}
        oldValue={oldValue?.description}
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}

function PsychiatricCare({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: PsychiatricCareType | null | undefined;
  translations: Translations;
  value: PsychiatricCareType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.psychiatric_care.label}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <StringField
        testId="psychiatric_care_description"
        value={value?.description}
        oldValue={oldValue?.description}
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}

function Medication({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: MedicationType | null | undefined;
  translations: Translations;
  value: MedicationType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.medication}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <StringField
        testId="medication_description"
        value={value?.description}
        oldValue={oldValue?.description}
        withDiff={withDiff}
      />
      <EmptiableField
        testId="blood_thinner"
        title={translations.patient.takesBloodThinner}
        value={value?.blood_thinner}
        oldValue={oldValue?.blood_thinner}
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}

function Injection({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: InjectionType | null | undefined;
  translations: Translations;
  value: InjectionType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.injection}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <StringField
        testId="injection_description"
        value={value?.description}
        oldValue={oldValue?.description}
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}

function AccessDrainages({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: AccessDrainagesType | null | undefined;
  translations: Translations;
  value: AccessDrainagesType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.accessDrainages.accessAndDrainages}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <EmptiableField
        title={translations.patient.careNeeds.accessDrainages.trachealCannula}
        value={value?.tracheal_cannula}
        testId="tracheal_cannula"
        oldValue={oldValue?.tracheal_cannula}
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.careNeeds.accessDrainages.gastricTube}
        value={value?.gastric_tube}
        testId="gastric_tube"
        oldValue={oldValue?.gastric_tube}
        withDiff={withDiff}
      />
      <EmptiableField
        title={
          translations.patient.careNeeds.accessDrainages.centralVenousCatheter
        }
        value={value?.central_venous_catheter}
        testId="central_venous_catheter"
        oldValue={oldValue?.central_venous_catheter}
        withDiff={withDiff}
      />
      <EmptiableField
        title={
          translations.patient.careNeeds.accessDrainages
            .peripheralVenousCatheter
        }
        value={value?.peripheral_venous_catheter}
        testId="peripheral_venous_catheter"
        oldValue={oldValue?.peripheral_venous_catheter}
        withDiff={withDiff}
      />
      <EmptiableField
        title={
          translations.patient.careNeeds.accessDrainages.suprapubicCatheter
        }
        value={value?.suprapubic_catheter}
        testId="suprapubic_catheter"
        oldValue={oldValue?.suprapubic_catheter}
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.careNeeds.accessDrainages.permanentCatheter}
        value={value?.permanent_catheter}
        testId="permanent_catheter"
        oldValue={oldValue?.permanent_catheter}
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.careNeeds.accessDrainages.drainage}
        value={value?.drainage}
        testId="drainage"
        oldValue={oldValue?.drainage}
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.intravenousInjection}
        value={value?.intravenous_injection}
        testId="intravenous_injection"
        oldValue={oldValue?.intravenous_injection}
        withDiff={withDiff}
      />
      <EmptiableField
        title={translations.patient.careNeeds.accessDrainages.other}
        value={value?.other_access_drainages}
        testId="other_access_drainages"
        oldValue={oldValue?.other_access_drainages}
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}

function Remarks({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.remarks}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <StringField
        value={value}
        oldValue={oldValue}
        withDiff={withDiff}
        testId="remarks"
      />
    </EmptiableCategory>
  );
}

function Compression({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: CompressionType | null | undefined;
  translations: Translations;
  value: CompressionType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.compression}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <StringField
        testId="compression_description"
        value={value?.description}
        oldValue={oldValue?.description}
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}
function PalliativeCare({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: PalliativeType | null | undefined;
  translations: Translations;
  value: PalliativeType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.palliativeCare.label}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <CategoryDescription
        value={value?.description}
        oldValue={oldValue?.description}
        withDiff={withDiff}
      />
      <BooleanField
        value={value?.palliative_certificate_needed}
        oldValue={oldValue?.palliative_certificate_needed}
        withDiff={withDiff}
        label={
          translations.patient.medicalDiagnosis.palliativeCertificateNeeded
        }
      />
    </EmptiableCategory>
  );
}

function BaseCare({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: BaseCareType | null | undefined;
  translations: Translations;
  value: BaseCareType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.baseCare}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <CategoryDescription
        value={value?.description}
        oldValue={oldValue?.description}
        withDiff={withDiff}
      />
      <StringField
        testId="companionship_grant"
        value={
          value?.companionship_grant
            ? translations.patient.careNeeds.carePaidByHealthInsurance
            : ""
        }
        oldValue={
          oldValue?.companionship_grant
            ? translations.patient.careNeeds.carePaidByHealthInsurance
            : ""
        }
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}

function BasicMedicalCare({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: { description: string | null | undefined } | null | undefined;
  translations: Translations;
  value: { description: string | null | undefined } | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.medicalCare}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <StringField
        testId="medical_care_description"
        value={value?.description}
        oldValue={oldValue?.description}
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}

function StaticCareForDisabledPatients({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: { description: string | null | undefined } | null | undefined;
  translations: Translations;
  value: { description: string | null | undefined } | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.ontologies.service.values.staticCareDisabledPatients}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <StringField
        testId="disabled_patients_description"
        value={value?.description}
        oldValue={oldValue?.description}
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}

function ImportantInformation({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: string | null | undefined;
  translations: Translations;
  value: string | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.titleImportantInformationCare}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <StringField
        value={value}
        oldValue={oldValue}
        withDiff={withDiff}
        testId="important_info"
      />
    </EmptiableCategory>
  );
}

function Companionship({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: CompanionshipType | null | undefined;
  translations: Translations;
  value: CompanionshipType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.companionship}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <CategoryDescription
        value={value?.description}
        oldValue={oldValue?.description}
        withDiff={withDiff}
      />
      <StringField
        testId="companionship_grant"
        value={
          value?.companionship_grant
            ? translations.patient.careNeeds.companionshipGrant
            : ""
        }
        oldValue={
          oldValue?.companionship_grant
            ? translations.patient.careNeeds.companionshipGrant
            : ""
        }
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}

function HelpAtHome({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: HelpAtHomeType | null | undefined;
  translations: Translations;
  value: HelpAtHomeType | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.helpAtHome}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <StringField
        testId="help_at_home_description"
        value={value?.description}
        oldValue={oldValue?.description}
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}

function Support({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: PatientNeedSupport | null | undefined;
  translations: Translations;
  value: PatientNeedSupport | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.needSupport}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <BooleanField
        value={value?.eating}
        oldValue={oldValue?.eating}
        label={translations.patient.careNeeds.withEating}
        withDiff={withDiff}
      />
      <BooleanField
        value={value?.body_care}
        oldValue={oldValue?.body_care}
        label={translations.patient.careNeeds.withBodyCare}
        withDiff={withDiff}
      />
      <BooleanField
        value={value?.dressing}
        oldValue={oldValue?.dressing}
        label={translations.patient.careNeeds.withDressing}
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}

function Monitoring({
  empty,
  oldValue,
  translations,
  value,
  withDiff,
}: {
  empty: boolean;
  oldValue: PatientNeedMonitoring | null | undefined;
  translations: Translations;
  value: PatientNeedMonitoring | null | undefined;
  withDiff: boolean;
}) {
  return (
    <EmptiableCategory
      empty={empty}
      title={translations.patient.careNeeds.monitoring}
      value={value}
      oldValue={oldValue}
      withDiff={withDiff}
    >
      <EmptiableField
        prefix={translations.patient.careNeeds.circulation.label}
        value={value?.circulation}
        testId="circulation"
        oldValue={oldValue?.circulation}
        withDiff={withDiff}
      />
      <EmptiableField
        prefix={translations.patient.careNeeds.respiration.label}
        value={value?.respiration}
        testId="respiration"
        oldValue={oldValue?.respiration}
        withDiff={withDiff}
      />
      <EmptiableField
        prefix={translations.patient.careNeeds.metabolism.label}
        value={value?.metabolism}
        testId="metabolism"
        oldValue={oldValue?.metabolism}
        withDiff={withDiff}
      />
    </EmptiableCategory>
  );
}

export const careneedsFilters: {
  [key: string]: {
    exists: any;
    notEmpty?: any;
    valueGetter: ValueGetter;
  };
} = {
  palliative_care: {
    valueGetter: (auction) => auction?.patient?.care_needs?.palliative,
    exists: (care_needs: CareNeedsType | null | undefined) =>
      care_needs?.palliative != null,
    notEmpty: (care_needs: CareNeedsType | null | undefined) =>
      care_needs?.palliative != null &&
      (care_needs.palliative.description != null ||
        care_needs.palliative.palliative_certificate_needed != null),
  },
  nutrition: {
    valueGetter: (auction) => auction?.patient?.care_needs?.nutrition,
    exists: (care_needs: CareNeedsType | null | undefined) =>
      care_needs?.nutrition && true,
    notEmpty: (care_needs: CareNeedsType | null | undefined) =>
      care_needs?.nutrition &&
      ((care_needs.nutrition &&
        stringFilled(care_needs.nutrition.parenteral_nutrition)) ||
        (care_needs.nutrition &&
          stringFilled(care_needs.nutrition.enteral_nutrition))) === true,
  },
  incontinence: {
    valueGetter: (auction) => auction?.patient?.care_needs?.incontinence,
    exists: (care_needs: CareNeedsType | null | undefined) =>
      care_needs?.incontinence && true,
    notEmpty: (care_needs: CareNeedsType | null | undefined) =>
      care_needs?.incontinence &&
      (stringFilled(care_needs.incontinence.urinary_incontinence) ||
        (care_needs.incontinence &&
          stringFilled(care_needs.incontinence.fecal_incontinence))) === true,
  },
  breathing: {
    valueGetter: (auction) => auction?.patient?.care_needs?.breathing,
    exists: (care_needs: CareNeedsType | null | undefined) =>
      care_needs?.breathing && true,
    notEmpty: (care_needs: CareNeedsType | null | undefined) =>
      care_needs?.breathing &&
      (stringFilled(care_needs.breathing.tracheostomy) ||
        care_needs.breathing?.specialised_intensive_nurse ||
        (care_needs.breathing &&
          stringFilled(care_needs.breathing.oxygen_therapy))) === true,
  },
  Dialysis: {
    valueGetter: (auction) => auction?.patient?.care_needs?.dialysis,
    exists: (care_needs: CareNeedsType | null | undefined) =>
      care_needs?.dialysis && true,
    notEmpty: (care_needs: CareNeedsType | null | undefined) =>
      care_needs?.dialysis &&
      (stringFilled(care_needs.dialysis.peritoneal_dialysis) ||
        stringFilled(care_needs.dialysis.hemodialysis)) === true,
  },
  psychiatric_care: {
    valueGetter: (auction) => auction?.patient?.care_needs?.psychiatric_care,
    exists: (care_needs: CareNeedsType | null | undefined) =>
      care_needs?.psychiatric_care && true,
    notEmpty: (care_needs: CareNeedsType | null | undefined) =>
      care_needs && descriptionCategory(care_needs.psychiatric_care),
  },
  Wounds: {
    valueGetter: (auction) => auction?.patient?.care_needs?.wound_care,
    exists: (care_needs: CareNeedsType | null | undefined) =>
      care_needs?.wound_care && true,
    notEmpty: (care_needs: CareNeedsType | null | undefined) =>
      care_needs && descriptionCategory(care_needs.wound_care),
  },
  medication: {
    valueGetter: (auction) => auction?.patient?.care_needs?.medication,
    exists: (care_needs: CareNeedsType | null | undefined) =>
      care_needs?.medication && true,
    notEmpty: (care_needs: CareNeedsType | null | undefined) =>
      (care_needs && descriptionCategory(care_needs.medication)) ||
      care_needs?.medication?.blood_thinner !== null,
  },

  compression: {
    valueGetter: (auction) => auction?.patient?.care_needs?.compression,
    exists: (care_needs: CareNeedsType | null | undefined) =>
      care_needs?.compression && true,
    notEmpty: (care_needs: CareNeedsType | null | undefined) =>
      care_needs && descriptionCategory(care_needs.compression),
  },
  injection: {
    valueGetter: (auction) => auction?.patient?.care_needs?.injection,
    exists: (care_needs: CareNeedsType | null | undefined) =>
      care_needs?.injection && true,
    notEmpty: (care_needs: CareNeedsType | null | undefined) =>
      care_needs && descriptionCategory(care_needs.injection),
  },
  access_drainages: {
    valueGetter: (auction) => auction?.patient?.care_needs?.access_drainages,
    exists: (care_needs: CareNeedsType | null | undefined) =>
      care_needs?.access_drainages && true,
    notEmpty: (care_needs: CareNeedsType | null | undefined) =>
      care_needs?.access_drainages &&
      (care_needs.access_drainages.tracheal_cannula != null ||
        care_needs.access_drainages.gastric_tube != null ||
        care_needs.access_drainages.central_venous_catheter != null ||
        care_needs.access_drainages.peripheral_venous_catheter != null ||
        care_needs.access_drainages.suprapubic_catheter != null ||
        care_needs.access_drainages.permanent_catheter != null ||
        care_needs.access_drainages.drainage != null ||
        care_needs.access_drainages.intravenous_injection != null ||
        care_needs.access_drainages.other_access_drainages != null),
  },
  base_care: {
    valueGetter: (auction) => auction?.patient?.care_needs?.base_care,
    exists: (care_needs: CareNeedsType | null | undefined) =>
      care_needs?.base_care && true,
    notEmpty: (care_needs: CareNeedsType | null | undefined) =>
      (care_needs?.base_care &&
        (stringFilled(care_needs.base_care.description) ||
          care_needs.base_care?.companionship_grant)) === true,
  },

  important_information: {
    valueGetter: (auction) =>
      auction?.patient?.care_needs?.important_information,
    exists: (care_needs: CareNeedsType | null | undefined) =>
      care_needs?.important_information && true,
    notEmpty: (care_needs: CareNeedsType | null | undefined) =>
      stringFilled(care_needs?.important_information) === true,
  },

  basic_medical_care: {
    valueGetter: (auction) => auction?.patient?.care_needs?.basic_medical_care,
    exists: (care_needs: CareNeedsType | null | undefined) =>
      care_needs?.basic_medical_care && true,
    notEmpty: (care_needs: CareNeedsType | null | undefined) =>
      !!care_needs?.basic_medical_care?.description,
  },

  static_care_disabled_patients: {
    valueGetter: (auction) =>
      auction?.patient?.care_needs?.static_care_disabled_patients,
    exists: (care_needs: CareNeedsType | null | undefined) =>
      !!care_needs?.static_care_disabled_patients,
    notEmpty: (care_needs: CareNeedsType | null | undefined) =>
      !!care_needs?.static_care_disabled_patients?.description,
  },

  companionship: {
    valueGetter: (auction) => auction?.patient?.care_needs?.companionship,
    exists: (care_needs: CareNeedsType | null | undefined) =>
      care_needs?.companionship && true,
    notEmpty: (care_needs: CareNeedsType | null | undefined) =>
      (care_needs?.companionship &&
        (stringFilled(care_needs.companionship.description) ||
          care_needs.companionship?.companionship_grant)) === true,
  },
  help_at_home: {
    valueGetter: (auction) => auction?.patient?.care_needs?.help_at_home,
    exists: (care_needs: CareNeedsType | null | undefined) =>
      care_needs?.help_at_home && true,
    notEmpty: (care_needs: CareNeedsType | null | undefined) =>
      care_needs && descriptionCategory(care_needs.help_at_home),
  },
  remarks: {
    valueGetter: (auction) => auction?.patient?.care_needs?.remarks,
    exists: (care_needs: CareNeedsType | null | undefined) =>
      care_needs?.remarks != null && care_needs.remarks != "",
    notEmpty: (care_needs: CareNeedsType | null | undefined) =>
      care_needs?.remarks != null && care_needs.remarks != "",
  },
  support: {
    valueGetter: (auction) => auction?.patient?.care_needs?.support,
    exists: (care_needs: CareNeedsType | null | undefined) =>
      care_needs?.support != null,
    notEmpty: (care_needs: CareNeedsType | null | undefined) =>
      care_needs?.support != null &&
      (care_needs.support.eating ||
        care_needs.support.body_care ||
        care_needs.support.dressing),
  },
  monitoring: {
    valueGetter: (auction) => auction?.patient?.care_needs?.monitoring,
    exists: (care_needs: CareNeedsType | null | undefined) =>
      care_needs?.monitoring != null,
    notEmpty: (care_needs: CareNeedsType | null | undefined) =>
      care_needs &&
      ((care_needs.monitoring != null &&
        stringFilled(care_needs.monitoring.circulation)) ||
        (care_needs.monitoring != null &&
          stringFilled(care_needs.monitoring.respiration)) ||
        (care_needs.monitoring != null &&
          stringFilled(care_needs.monitoring.metabolism))),
  },
};

function getWhitelist(auction: Auction) {
  return {
    nutrition: true,
    support: true,
    palliative_care: true,
    psychiatric_care: true,
    incontinence: true,
    breathing: true,
    Dialysis: true,
    Wounds: true,
    medication: true,
    monitoring: true,
    companionship: true,
    compression: true,
    injection: true,
    important_information: true,
    base_care: true,
    basic_medical_care: true,
    static_care_disabled_patients: true,
    help_at_home: true,
    remarks: true,
    access_drainages: auction.assessment_variant !== "mobile-short",
  };
}

const componentMapping = [
  {
    Component: Support,
    key: "support",
  },
  {
    Component: PalliativeCare,
    key: "palliative_care",
  },
  {
    Component: PsychiatricCare,
    key: "psychiatric_care",
  },
  {
    Component: Nutrition,
    key: "nutrition",
  },
  {
    Component: Incontinence,
    key: "incontinence",
  },
  {
    Component: Breathing,
    key: "breathing",
  },
  {
    Component: Dialysis,
    key: "Dialysis",
  },
  {
    Component: Wounds,
    key: "Wounds",
  },
  {
    Component: Medication,
    key: "medication",
  },
  {
    Component: Monitoring,
    key: "monitoring",
  },
  {
    Component: Compression,
    key: "compression",
  },
  {
    Component: Injection,
    key: "injection",
  },
  {
    Component: AccessDrainages,
    key: "access_drainages",
  },
  {
    Component: ImportantInformation,
    key: "important_information",
  },

  {
    Component: BaseCare,
    key: "base_care",
  },
  {
    Component: BasicMedicalCare,
    key: "basic_medical_care",
  },
  {
    Component: StaticCareForDisabledPatients,
    key: "static_care_disabled_patients",
  },
  {
    Component: Companionship,
    key: "companionship",
  },
  {
    Component: HelpAtHome,
    key: "help_at_home",
  },
  {
    Component: Remarks,
    key: "remarks",
  },
];

const getCategories = ({
  auction,
  oldAuction,
}: {
  auction: Auction;
  oldAuction: Auction | null | undefined;
}) => {
  const whitelist = getWhitelist(auction);

  return componentMapping
    .filter(({ key }) => {
      const display = whitelist[key as keyof typeof whitelist];

      if (display == null) {
        console.error("Missing whitelist for component", key);
        return true;
      }

      return display === true;
    })
    .map(({ Component, key }) => ({
      Component,
      key,
      notEmpty:
        (oldAuction != null &&
          careneedsFilters[key].notEmpty(oldAuction.patient.care_needs)) ||
        careneedsFilters[key].notEmpty(auction.patient.care_needs),
      exists:
        (oldAuction != null &&
          careneedsFilters[key].exists(oldAuction.patient.care_needs)) ||
        careneedsFilters[key].exists(auction.patient.care_needs),
      valueGetter: careneedsFilters[key].valueGetter,
    }));
};

export default function CareNeeds({
  auction,
  getOntology,
  oldAuction,
}: {
  auction: Auction;
  getOntology: GetOntologyType;
  oldAuction?: Auction;
}) {
  const translations = useTranslations();
  const { Card } = usePatientInfoContext();
  const withDiff = oldAuction != null;

  if (
    withDiff
      ? !auction.patient.care_needs && !oldAuction?.patient.care_needs
      : !auction.patient.care_needs
  )
    return null;

  const categories = getCategories({
    auction,
    oldAuction,
  });
  if (!exists(categories)) return null;

  return (
    <PatientInfoSlugContext.Provider
      value={{
        patientId: auction.patient.id,
        auctionId: auction.id,
        assessmentSlug: ASSESSMENT_SLUG_NEEDS,
      }}
    >
      <Card
        title={
          auction.search_type === SEARCH_TYPE_CARE
            ? translations.patient.careNeeds.stepperTitleCare
            : translations.patient.careNeeds.stepperTitle
        }
      >
        <Categories
          getOntology={getOntology}
          translations={translations}
          categories={categories}
          auction={auction}
          oldAuction={oldAuction}
        />
      </Card>
    </PatientInfoSlugContext.Provider>
  );
}
